import React from 'react';
import '../Common/Common.css';

function TermsOfService() {
  return (
    <div className="body">
      <div>
        <h1 className="page-title">Term of Service</h1>
        <div className="updated-information-div">
          <h1 className="font-primary-color updated-information-font">
            Effective Date: 25-Feb-2025<br />
            Last Updated: 25-Feb-2025
          </h1>
        </div>

        <h2 className="content-subtitle">1. Introduction</h2>
        <p>Welcome to Fancian Korea Inc. (the "Company," "we," "our," "us"). These Terms of Service (“Terms”) outline the rules and conditions for you and other users 
          when accessing and using our services, which include our websites, SMS, APIs, email notifications,
          applications, buttons, widgets, advertisements, commerce services, and other related offerings. 
          By using these services, you acknowledge and agree to comply with these Terms.
        </p>
        <hr className="hr"/>

        <h2 className="content-subtitle">2. Who can use the services</h2>
        <p>You are permitted to use the Services only if you can enter into a legally binding agreement with us 
          and are not prohibited from receiving services under the applicable laws.
          Additionally, you must be at least 13 years old to access the Services.  <br /><br />
          If you (i) accept these Terms and/or use the Services, thereby agreeing to these Terms, 
          or (ii) accept these Terms on behalf of a minor (someone under the legal age of majority in 
          their respective country), a company, organization, government, or any other legal entity, 
          you confirm and guarantee that you have the authority to do so. Furthermore, you acknowledge 
          that you can legally bind the minor and/or entity to these Terms where applicable.  <br /><br />
          In these Terms, “you” and “your” refer either to the individual accepting the Terms or, 
          where relevant, to the minor (as mentioned in (i)) or the entity specified in (ii).
        </p>
        <hr className="hr"/>

        <h2 className="content-subtitle">3. Privacy</h2>
        <p>Our <a href="/privacy-policy">Privacy Policy (https://www.fancian.com/privacy-policy)</a> 
          explains how we collect, process, and manage the information you provide when using our Services. 
          By using the Services, you acknowledge and agree to the collection and use of this information as outlined in the Privacy Policy. 
          This includes the transfer of your data to the United States, and other countries where we and our affiliates may store, process, and use it.
        </p>
        <hr className="hr"/>

        <h2 className="content-subtitle">4. Content on the services</h2>
        <p>You are solely responsible for how you use the Services and any Content you provide, ensuring compliance with all applicable laws, rules, and regulations.
          Only share Content that you are comfortable making available to others.  <br /><br />
          Your use of or reliance on any Content or materials shared through the Services is at your own risk. 
          We do not endorse, verify, or guarantee the accuracy, reliability, completeness, or truthfulness of any Content 
          or communications posted via the Services, nor do we support any opinions or claims expressed.  <br /><br />
          By using the Services, you acknowledge that you may encounter Content that is offensive, misleading, inaccurate, or otherwise inappropriate. 
          Some postings may even be deceptive or mislabeled. The responsibility for any Content rests solely with its original creator. 
          While we do not actively monitor or control the Content shared through the Services, we cannot assume liability for it.
        </p>
        <hr className="hr"/>

        <h2 className="content-subtitle">5. Your rights and grant of rights in the content</h2>
        <p>
          You retain ownership of any Content you submit, post, or display on or through the Services.
          This means that any audio, photos, videos, or other materials you share remain your property.
          By posting, submitting, or displaying Content on the Services, you grant us a global, non-exclusive, royalty-free license (including the right to sublicense) to use, copy, 
          reproduce, modify, adapt, process, publish, transmit, display, distribute, upload, and download your Content in any media or distribution format, 
          whether currently known or developed in the future. This includes curating, transforming, and translating your Content. 
          This license allows us to share your Content with the world and to permit others to do the same. <br /><br />
          You also agree that this license gives us the right to:
        </p>
        <ol>
          <li>Analyze text and other data you provide to enhance and improve our Services, including training our machine learning and AI models.</li>
          <li>Make your Content available to companies, organizations, or individuals for purposes such as syndication, distribution, reposting, promotion, 
            or publication across various media platforms, subject to our terms and conditions.
          </li>
        </ol>
        <p>
          You acknowledge that no compensation will be provided for the Content you submit, post, or make available through the Services, 
          as your use of the Services is considered sufficient compensation for granting these rights. <br /><br />
          We maintain a set of evolving guidelines for how our ecosystem partners may interact with your Content, ensuring that your rights are respected. 
          Additionally, we may modify, adapt, or reformat your Content to suit different media platforms as it is distributed, syndicated, published, or broadcast by us or our partners. <br /><br />
          By submitting Content, you confirm that you have all necessary rights, licenses, permissions, and authority to grant us these rights. 
          You also ensure that your Content does not contain copyrighted or proprietary material unless you have the legal right to post it and grant us the necessary license.
        </p>
        <hr className="hr"/>

        <h2 className="content-subtitle">6. Using the services</h2>
        <p>
          Our Services are continuously evolving, and we may modify them at any time at our discretion. 
          This includes making changes to features, discontinuing services (either temporarily or permanently), or limiting access to certain functionalities. 
          We also reserve the right to impose restrictions on usage and storage. Additionally, we may remove, limit, or refuse to distribute Content, adjust its visibility, 
          suspend or terminate accounts, and reclaim usernames without liability to you. <br /><br />
          By using the Services, you acknowledge and agree that we and our third-party partners may display advertisements in connection with the Services, 
          including alongside Content submitted by you or others. <br /><br />
          We also reserve the right to access, review, retain, and disclose any information when we reasonably believe it is necessary to:
        </p>
        <ol>
          <li>Comply with applicable laws, regulations, legal processes, or government requests.</li>
          <li>Enforce these Terms, including investigating potential violations.</li>
          <li>Detect, prevent, or address fraud, security threats, or technical issues.</li>
          <li>Respond to user support inquiries.</li>
          <li>Protect the rights, property, and safety of Fancian, its users, and the public.</li>
        </ol>
        <p>
          We do not share personally identifiable information with third parties except as outlined in our <a href="/privacy-policy">Privacy Policy</a>.<br /><br />
          Certain features or services on X may have additional terms and conditions. 
          If you choose to use or pay for these services, you agree to be bound by their respective terms, which will become part of your agreement with us. 
          In the event of a conflict between these Terms and any applicable additional terms, the additional terms will take precedence for the specific services they govern.
        </p>
        <hr className="hr"/>

        <h2 className="content-subtitle">7. Your account</h2>
        <p>
          To access certain features of the Services, you may need to create an account. 
          You are responsible for keeping your account secure by using a strong password, limiting its use to this account, 
          and enabling two-factor authentication via an authenticator app or security key. 
          We are not liable for any loss or damage resulting from your failure to protect your account. <br /><br />
          You have control over most communications from the Services. 
          However, some essential notifications, such as service announcements and administrative messages, are integral to your account and the Services, 
          and you may not be able to opt out of receiving them. <br /><br />
          f you have linked a phone number to your account and later change or deactivate it, 
          you must update your account details to prevent messages from being sent to the new owner of your previous number.
        </p>
        <hr className="hr"/>

        <h2 className="content-subtitle">8. Your license to use the services</h2>
        <p>
          We grant you a personal, worldwide, royalty-free, non-exclusive, and non-transferable license to use the software provided as part of the Services. 
          This license is solely for the purpose of enabling you to access and benefit from the Services as intended and permitted under these Terms. 
          You may not assign, sell, transfer, share, or otherwise distribute this license to any other individual or entity without Fancian’s explicit written consent. <br /><br />
          The Services are protected by copyright, trademark, and other applicable laws in the United States and other jurisdictions. 
          These Terms do not grant you any rights to use Fancian’s name, trademarks, logos, domain names, or any other distinctive brand elements.
          All rights, ownership, and interests in the Services (excluding user-generated Content) remain the exclusive property of Fancian and its licensors. <br /><br />
          Any feedback, comments, or suggestions you provide about Fancian or the Services are entirely voluntary. We are free to use, implement, or disregard such feedback at our sole discretion, without any obligation or compensation to you.
        </p>
        <hr className="hr"/>

        <h2 className="content-subtitle">9. General</h2>
        <p>
          We may update these Terms periodically. Any changes will apply moving forward and will not have retroactive effect. 
          The most up-to-date version of the Terms will always be available at <a href="/tos">https://www.fancian.com/tos</a> and will govern your use of the Services. <br /><br />
          If there are significant revisions, we will make an effort to notify you, such as through a service notification or an email sent to the address linked to your account. 
          By continuing to use or access the Services after the updated Terms take effect, you agree to be bound by the revised Terms.
        </p>
        <hr className="hr"/>
      </div>
    </div>
  )
}

export default TermsOfService;