import React from 'react';
import '../Common/Common.css';

function CookiePolicy() {
  return (
    <div className="body">
      <div>
        <h1 className="page-title">Cookie Policy</h1>
        <div className="updated-information-div">
          <h1 className="font-primary-color updated-information-font">
            Effective Date: 25-Feb-2025<br />
            Last Updated: 25-Feb-2025
          </h1>
        </div>

        <h2 className="content-subtitle">1. Introduction</h2>
        <p>This Cookie Policy explains how Fancian Korea Inc. ("we," "us," or "our") uses cookies and similar tracking 
          technologies when you visit our website <a href="/">https://www.fancian.com</a> (the "Website").</p>
        <p>By using our Website, you consent to the use of cookies as described in this policy.</p>
        <hr className="hr"/>

        <h2 className="content-subtitle">2. What are cookies?</h2>
        <p>Cookies are small text files stored on your device (computer, tablet, or mobile phone) when you visit a website. 
          They help improve user experience by remembering preferences, login details, and other useful information.</p>
        <hr className="hr"/>

        <h2 className="content-subtitle">3. Types of Cookies We Use</h2>
        <p>We use different types of cookies for various purposes:</p>
        <ul>
          <li>Essential Cookies: Necessary for the proper functioning of the Website (e.g., authentication, security).</li>
          <li>Performance & Analytics Cookies: Collect anonymous data to analyze Website traffic and improve performance (e.g., Google Analytics).</li>
          <li>Functional Cookies: Store user preferences to enhance usability (e.g., language selection).</li>
          <li>Advertising & Targeting Cookies: Used to deliver relevant ads based on browsing behavior.</li>
        </ul>
        <hr className="hr"/>

        <h2 className="content-subtitle">4. How We Use Cookies</h2>
        <p>We use cookies to:</p>
        <ul>
          <li>Ensure the Website functions correctly.</li>
          <li>Improve Website performance and user experience.</li>
          <li>Analyze visitor interactions and behavior.</li>
          <li>Provide personalized content and targeted advertisements.</li>
        </ul>
        <hr className="hr"/>

        <h2 className="content-subtitle">5. Third-Party Cookies</h2>
        <p>Some third-party services we use (such as Google, Facebook, or advertising networks) may set cookies on our Website. 
          These cookies are subject to the third party's own privacy policies.</p>
        <hr className="hr"/>

        <h2 className="content-subtitle">6. Managing and Disabling Cookies</h2>
        <p>You can control or delete cookies through your browser settings. Most browsers allow you to:</p>
        <ul>
          <li>View and delete stored cookies.</li>
          <li>Block specific cookies.</li>
          <li>Set preferences for how cookies are used.</li>
        </ul>
        <p>To adjust your cookie settings, refer to the instructions for your browser:</p>
        <ul>
          <li>Google Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=en&co=GENIE.Platform%3DDesktop">Manage cookies</a></li>
          <li>Mozilla Firefox: <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop">Manage cookies</a></li>
          <li>Safari: <a href="https://support.apple.com/ko-kr/guide/safari/sfri11471/mac">Manage cookies</a></li>
          <li>Microsoft Edge: <a href="https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d">Manage cookies</a></li>
        </ul>
        <p>Please note that disabling cookies may affect certain functionalities of our Website.</p>
        <hr className="hr"/>

        <h2 className="content-subtitle">7. Changes to This Policy</h2>
        <p>We may update this Cookie Policy from time to time. Any changes will be posted on this page with the updated "Effective Date."</p>
        <hr className="hr"/>
      </div>
    </div>
  )
}
export default CookiePolicy;