import React from 'react';
import '../Common/Common.css';

function PrivacyPolicy() {
  return (
    <div className="body">
      <div>
        <h1 className="page-title">Privacy Policy</h1>
        <div className="updated-information-div">
          <h1 className="font-primary-color updated-information-font">
            Effective Date: 25-Feb-2025<br />
            Last Updated: 25-Feb-2025
          </h1>
        </div>

        <h2 className="content-subtitle">1. Introduction</h2>
        <p>Welcome to Fancian Korea Inc. (the "Company," "we," "our," "us"). We are committed to protecting your privacy and ensuring that your personal information is handled safely and responsibly. 
          This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website <a href="/">https://www.fancian.com</a> and use our services.</p>
        <hr className="hr"/>
        
        <h2 className="content-subtitle">2. Information We Collect</h2>
        <p>We may collect and process the following types of information:</p>
        <ul>
            <li><strong>Personal Information:</strong> Name, email address, phone number, and any other information you provide.</li>
            <li><strong>Usage Data:</strong> Information about your interactions with our website, such as IP address, browser type, and pages visited.</li>
            <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar technologies to enhance user experience and track website performance.</li>
        </ul>
        <hr className="hr"/>

        <h2 className="content-subtitle">3. How We Use Your Information</h2>
        <p>We may use your information for the following purposes:</p>
        <ul>
            <li>To provide, operate, and maintain our services</li>
            <li>To personalize your experience on our website</li>
            <li>To improve customer service and respond to inquiries</li>
            <li>To analyze website traffic and user behavior</li>
            <li>To send periodic emails regarding updates, promotions, or other relevant information</li>
        </ul>
        <hr className="hr"/>

        <h2 className="content-subtitle">4. How We Share Your Information</h2>
        <p>We do not sell or rent your personal information. However, we may share your information with:</p>
        <ul>
            <li><strong>Service Providers:</strong> Third-party vendors who assist in operating our website and providing services</li>
            <li><strong>Legal Authorities:</strong> When required by law, regulation, or legal process</li>
            <li><strong>Business Transfers:</strong> In the event of a merger, sale, or asset transfer</li>
        </ul>
        <hr className="hr"/>

        <h2 className="content-subtitle">5. Data Security</h2>
        <p>We implement appropriate technical and organizational measures to protect your personal data from unauthorized access, alteration, disclosure, or destruction. However, no data transmission over the internet is entirely secure.</p>
        <hr className="hr"/>

        <h2 className="content-subtitle">6. Your Rights and Choices</h2>
        <p>Depending on your jurisdiction, you may have the right to:</p>
        <ul>
            <li>Access, correct, or delete your personal information</li>
            <li>Opt-out of marketing communications</li>
            <li>Restrict or object to data processing</li>
            <li>Withdraw consent where applicable</li>
        </ul>
        <hr className="hr"/>

        <h2 className="content-subtitle">7. Third-Party Links</h2>
        <p>Our website may contain links to third-party sites. We are not responsible for the privacy practices of such sites, and we encourage you to review their privacy policies.</p>
        <hr className="hr"/>

        <h2 className="content-subtitle">8. Children's Privacy</h2>
        <p>Our services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children.</p>
        <hr className="hr"/>

        <h2 className="content-subtitle">9. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date.</p>
        <hr className="hr"/>
      </div>
    </div>
  )
}
export default PrivacyPolicy;