import React, { useState } from 'react';
import './Contact.css';
import '../Common/Common.css';

function Contact() {

  const [formData, setFormData] = useState({
    email: "",
    message: "",
    consent: false,
  });

  const [status, setStatus] = useState(null);

  // 입력값 핸들링
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // 폼 제출 핸들링
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.email || !formData.message) {
      setStatus("Please fill in all required fields.");
      return;
    }
  };

  return (
    <div className="contact flex justify-center items-center">
      <div className="w-full max-w-lg bg-white shadow-lg rounded-lg p-8">
        <h2 className="text-3xl font-bold text-black mb-4">Contact Fancian</h2>
        <p className="text-gray-600 mb-6">
          Have a question? Let us know how we can help. If you're looking for
          more information, contact our{" "}
          <a href="#" className="text-purple-600 font-bold">
            sales team.
          </a>
        </p>

        <form onSubmit={handleSubmit} className="space-y-4">
          <label className="block font-semibold">Work Email *</label>
          <input
            type="email"
            name="email"
            placeholder="Your business email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md"
            required
          />

          <label className="block font-semibold">What can we help with? *</label>
          <textarea
            name="message"
            placeholder="Type your message here..."
            value={formData.message}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md h-28"
            required
          />

          <div className="flex items-center">
            <input
              type="checkbox"
              name="consent"
              checked={formData.consent}
              onChange={handleChange}
              className="mr-2"
            />
            <label className="text-sm">
              I consent to receive marketing messages from Fancian.
            </label>
          </div>

          <p className="text-xs text-gray-600">
            By clicking “Submit” I agree to Fancian’s{" "}
            <a href="/privacy-policy" className="text-purple-600 font-bold">
              Privacy Policy
            </a>
            .
          </p>

          {status && <p className="text-sm text-red-500">{status}</p>}

          <button
            type="submit"
            className="w-full bg-purple-600 text-white py-2 rounded-md font-semibold text-lg hover:bg-purple-700 transition"
          >
            CONTACT US
          </button>
        </form>
      </div>
    </div>
  )
}

export default Contact;