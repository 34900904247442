import React from "react";
import Header from './header/Header';
import Footer from './footer/Footer';
import Home from './page/Home/Home';
import Contact from './page/Contact/Contact';
import CookiePolicy from './page/CookiePolicy/CookiePolicy';
import Notice from './page/Notice/Notice';
import PrivacyPolicy from './page/PrivacyPolicy/PrivacyPolicy';
import TermsOfService from './page/TermsOfService/TermsOfService';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const App = () => (
  <Router>
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow flex items-center justify-center">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/notice" element={<Notice />} />
          <Route path="/tos" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </main>
      <Footer />
    </div>
  </Router>
);

export default App;
