import React from 'react';
import './Header.css';
import FancianLogo from '../assets/images/logo.png';


function Header() {
  return (
    <header className="header">
      <h1 className="logo-container header-title" onClick={() => window.location.href = "/"}>
        <img src={FancianLogo} alt="Fancian" className="logo" /> 
        Fancian
      </h1>
      <nav className="nav">
        <ul className="nav-list">
          <li className="nav-item">
            <a href="/notice" className="nav-link header-font">Notice</a>
          </li>
          <li className="nav-item">
            <a href="/tos" className="nav-link header-font">Terms of Service</a>
          </li>
          <li className="nav-item">
            <a href="/privacy-policy" className="nav-link header-font">Privacy policy</a>
          </li>
          <li className="nav-item">
            <a href="/contact" className="nav-link header-font">Contact</a>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Header;