import React from 'react';

function Notice() {
    return (
      <div className="contact flex justify-center items-center">
        <div className="w-full max-w-lg bg-white shadow-lg rounded-lg p-8 min-h-[550px]">
          <h2 className="text-3xl font-bold text-black mb-4">Notice of Incorporation</h2>
          <p className="text-gray-600 mb-6">
            Pursuant to Article 289 of the Korean Commercial Act and relevant regulations, Fancian Korea Inc. has completed its corporate registration as of 02.24, 2025.<br />
            Accordingly, we hereby announce the establishment of our corporation as follows:
          </p>
          <ul className="list-disc text-gray-600 mb-6 ml-8">
            <li>Company Name: Fancian Korea Inc.</li>
            <li>Registered Office: 402-J908ho, 4F, 13, Saimdang-ro 8-gil, Seocho-gu, Seoul, Republic of Korea</li>
            <li>Date of Incorporation: 02.24 2025</li>
            <li>Co-founder: Jungchul Kim, Jeong-jae Kim</li>
            <li>Capital: KRW 1,000,000</li>
            <li>Total authorized shares: 1,000,000 shares</li>
            <li>Shares issued at incorporation: 10,000 shares</li>
            <li>Par value per share: KRW 100</li>
          </ul>
          <p className="text-gray-600">
            02. 24, 2025 <br />
            Fancian Korea Inc. <br />
            Representative Director Jeong-jae Kim <br />
          </p>
        </div>
        <div className="w-full max-w-lg bg-white shadow-lg rounded-lg p-8 min-h-[550px] ml-8">
          <h2 className="text-3xl font-bold text-black mb-4">법인설립 공고</h2>
          <p className="text-gray-600 mb-6">
            주식회사 팬시안코리아는 「상법」 제289조 및 관련 법령에 따라 2025년 2월 24일자로 설립등기를 완료하였음을 공고합니다.
            이에 당사는 법인의 설립 내용을 다음과 같이 공고합니다.
          </p>
          <ul className="list-disc text-gray-600 mb-6 ml-8">
            <li>법인명: 주식회사 팬시안코리아</li>
            <li>본점 소재지: 서울특별시 서초구 사임당로8길 13, 4층 402-J908호</li>
            <li>설립일자: 2025년 2월 24일</li>
            <li>공동 대표이사: 김정출, 김정재</li>
            <li>자본금: 1,000,000원 </li>
            <li>발행 가능 주식 총수: 1,000,000 주</li>
            <li>설립 시 발행한 주식 수: 10,000 주</li>
            <li>1주당 액면가액: 100 원</li>
          </ul>
          <p className="text-gray-600">
            2025년 2월 24일 <br />
            주식회사 팬시안코리아 <br />
            대표이사 김정재 <br />
          </p>
        </div>
      </div>
    )
}

export default Notice;