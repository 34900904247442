import React from 'react';
import './Footer.css';
import {ReactComponent as FacebookSvg} from "../assets/icons/facebook.svg"
import {ReactComponent as InstagramSvg} from "../assets/icons/instagram.svg"
import {ReactComponent as TwitterSvg} from "../assets/icons/x.svg"

function Footer() {
  return (
    <footer className="footer">
      <div className="max-w-7xl mx-auto">
        {/* Company information */}
        <div className="text-sm mb-6">
          <h3 className="company-name">
            Fancian Korea Inc.<br /><br />
          </h3>
          <p className="leading-relaxed">
            Business registration number: OOO-OO-OOOOO | Co-founder : Jungchul Kim, Jeong-jae Kim<br />
            402-J908ho, 4F, 13, Saimdang-ro 8-gil, Seocho-gu, Seoul, Republic of Korea<br />
          </p>
        </div>
        {/* Social media icon */}
        <div className="flex space-x-4">
          <FacebookSvg className="icon w-6 h-6 text-gray-600 cursor-pointer hover:text-gray-800" />
          <InstagramSvg className="icon w-6 h-6 text-gray-600 cursor-pointer hover:text-gray-800" />
          <TwitterSvg className="icon w-6 h-6 text-gray-600 cursor-pointer hover:text-gray-800" />
        </div>
        {/* Footer link */}
        <div className="flex justify-center">
          <a className="footer-link" href="/cookie-policy">Cookie Policy</a>
        </div>
        <br /><br />

        <div className="flex justify-center space-x-4">
          <p className="footer-font">
            @ 2025 Fancian Korea Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer;