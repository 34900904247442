import React from 'react';
import '../Home/Home.css';
import FancianAppLogin from '../../assets/images/FancianAppLogin.png';
import FancianLogo from '../../assets/images/logo.png';

function Home() {
  return (
    <div className="home">
      <div className="top-pannel">
        <div className="top-title-div">
          <h1 className="top-title">Connecting people as one <br /> in the virtual world, <br /> beyond boundaries</h1>
        </div>
      </div>
      <div className="app-images-pannel">
        <img className="app-images" src={FancianAppLogin} />
      </div>
      <div className="middle-pannel">
        
      </div>
    </div>
  )
}

export default Home;